import React, { useEffect } from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import { ListingCard } from '../../components';

import css from './LandingPage.module.css';
import { insertItem } from '../../util/misc';
import { PopularCategories } from './Components/PopularCategories';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import ListingCardSkeleton from '../../components/ListingCard/ListingCardSkeleton';
const Carousel = loadable(
  () => import(/* webpackChunkName: "reactMultiCarousel" */ 'react-multi-carousel'),
  {
    fallback: <div>Loading...</div>,
  }
);

const Marquee = loadable(() => import(/* webpackChunkName: "Marquee" */ 'react-fast-marquee'));

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

const SectionUser = props => {
  const { sectionId } = props;

  useEffect(() => {
    import('react-multi-carousel/lib/styles.css');
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <section id={sectionId}>
      <Marquee className={css.runOfSiteCustom} speed={60} autoFill={true}>
        <span>
          New Year New Wardrobe -{' '}
          <a href="https://gleam.io/xWFHY/new-year-new-wardrobe-competition" target="_BLANK">
            Click here
          </a>{' '}
          for your chance to win 2 x $500 gift vouchers!
        </span>
      </Marquee>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        keyBoardControl={false}
        containerClass={css.caro}
      >
        <div className={css.caroImage}>
          <a href="/search">
            <img src="/static/carousel/slide-1-optimised.jpg" className={css.caroImageDesktop} />
            <img
              src="/static/carousel/mobile/carousel-1-final.jpg"
              className={css.caroImageMobile}
            />
          </a>
          <a href="/search" className={css.mobileCaroButton}>
            START HERE
          </a>
        </div>

        <div className={css.caroImage}>
          <a href="/l/draft/00000000-0000-0000-0000-000000000000/new/details">
            <img src="/static/carousel/slide-2-optimised.jpg" className={css.caroImageDesktop} />
            <img
              src="/static/carousel/mobile/carousel-2-final.jpg"
              className={css.caroImageMobile}
            />
          </a>
          <a
            href="/l/draft/00000000-0000-0000-0000-000000000000/new/details"
            className={css.mobileCaroButtonSecondary}
            // style={{ left: '50%' }}
          >
            SELL NOW
          </a>
        </div>

        <div className={css.caroImage}>
          <a href="/p/store-locations">
            <img src="/static/carousel/Website-Carousel_02.jpg" className={css.caroImageDesktop} />
            <img src="/static/carousel/mobile/carousel2.jpg" className={css.caroImageMobile} />
          </a>
          <a
            href="/p/store-locations"
            className={css.mobileCaroButtonSecondary}
            // style={{ left: '50%' }}
          >
            OUR LOCATIONS
          </a>
        </div>

        <div className={css.caroImage}>
          <a href="/p/about">
            <img src="/static/carousel/Website-Carousel_03.jpg" className={css.caroImageDesktop} />
            <img src="/static/carousel/mobile/carousel3.jpg" className={css.caroImageMobile} />
          </a>
          <a href="/p/about" className={css.mobileCaroButton}>
            ABOUT US
          </a>
        </div>
      </Carousel>
    </section>
  );
};

const newestArrivalsSection = props => {
  const { sectionId, listings, user, under50Listings } = props;

  const responsiveListingcaro = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 4, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
  };

  return (
    <>
      <section id={sectionId} className={css.newestArrivalsSection}>
        <h3>New Arrivals</h3>
        <div className={css.listingsContainer}>
          {listings.length === 0 && <ListingCardSkeleton />}

          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsiveListingcaro}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={false}
            removeArrowOnDeviceType={['mobile']}
            containerClass={css.listingCaro}
            // dotListClass="custom-dot-list-style"
            // itemClass="carousel-item-padding-40-px"
          >
            {listings.map(l => (
              <ListingCard
                listing={l}
                showAuthorInfo={false}
                className={css.listingCard}
                currentUser={user}
              />
            ))}
          </Carousel>
        </div>

        {under50Listings.length === 0 && <ListingCardSkeleton />}
        <h3>Under $50</h3>
        <div className={css.listingsContainer}>
          {under50Listings.length === 0 && <ListingCardSkeleton />}

          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsiveListingcaro}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={false}
            removeArrowOnDeviceType={['mobile']}
            containerClass={css.listingCaro}
            // dotListClass="custom-dot-list-style"
            // itemClass="carousel-item-padding-40-px"
          >
            {under50Listings.map(l => (
              <ListingCard
                listing={l}
                showAuthorInfo={false}
                className={css.listingCard}
                currentUser={user}
              />
            ))}
          </Carousel>
        </div>
      </section>
    </>
  );
};

const popularCategories = props => {
  const { sectionId } = props;
  return (
    <section id={sectionId} className={css.popularCategoriesSection}>
      <PopularCategories />
    </section>
  );
};

const SectionLatestProducts = props => {
  const { sectionId, listings, user } = props;

  return (
    <>
      <section id={sectionId} className={css.latestProductsSection}>
        <h3>Featured Listings</h3>
        <div className={css.listingsContainer}>
          {listings.length === 0 && <ListingCardSkeleton />}
          <ul className={css.listings}>
            {listings.map(l => (
              <li className={css.listing} key={l.id.uuid}>
                <ListingCard listing={l} showAuthorInfo={false} currentUser={user} />
              </li>
            ))}
          </ul>
        </div>
        <div className={css.buttonContainer}>
          <a href="/s" className={css.newButtonStyle}>
            VIEW ALL NEW ARRIVALS
          </a>
        </div>
      </section>
    </>
  );
};

const SectionOrangeCard = props => {
  const { sectionId, listings } = props;

  return (
    <>
      <div className={css.customSectionHeading}>
        <h3>Our Vision and Legacy</h3>
      </div>
      <section id={sectionId} className={css.orangeCardSection}>
        <div>
          <div>
            <h3>Our Brand, our experience, our planet</h3>
            <p>
              Sometimes we all need to change course, head in a new direction and rethink the way we
              do things. At Uturn we're showing the world that fashion can follow a new path. A path
              where style and circularity go hand in hand.
            </p>
            <a href="/p/corporate" className={css.newButtonStyle}>
              LEARN MORE
            </a>
          </div>
        </div>
      </section>
      <section id="df" className={css.infoSection}>
        <div></div>
        <div>
          <div>
            <div>
              <h2>Brand & Legacy</h2>
              <p>
                We work hard so that we can bring you products that make you feel good. Simply
                because we want to do the right thing. Right by you and right by the planet - for
                generations to come.
              </p>
              <a href="/p/about" className={css.newButtonStyle}>
                ABOUT US
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="popular-brands" className={css.popularBrands}>
        <h3>Popular Brands</h3>
        <div className={css.popularBrandsContainer}>
          <div>
            <a href="/search?brand=GORMAN">
              <img src="/static/popular-brands/Logo_Gorman.jpg" alt="Gorman" loading="lazy" />
            </a>
          </div>
          <div>
            <a href="/search?brand=COUNTRY%20ROAD">
              <img
                src="/static/popular-brands/Logo_CountryRoad.jpg"
                alt="Country Road"
                loading="lazy"
              />
            </a>
          </div>
          <div>
            <a href="/search?brand=ZIMMERMANN">
              <img
                src="/static/popular-brands/Logo_Zimmermann.jpg"
                alt="Zimmermann"
                loading="lazy"
              />
            </a>
          </div>
          <div>
            <a href="/search?brand=BEC%20AND%20BRIDGE">
              <img
                src="/static/popular-brands/Logo_BecBridge.jpg"
                alt="Bec + Bridge"
                loading="lazy"
              />
            </a>
          </div>
          <div>
            <a href="/search?brand=OROTON">
              <img src="/static/popular-brands/Logo_Oroton.jpg" alt="Oroton" loading="lazy" />
            </a>
          </div>
          <div>
            <a href="/search?brand=CAMILLA">
              <img src="/static/popular-brands/Logo_Camilla.jpg" alt="Camilla" loading="lazy" />
            </a>
          </div>
          <div>
            <a href="/search?brand=SCANLAN%20THEODODRE">
              <img
                src="/static/popular-brands/Logo_ScanlanTheodore.jpg"
                alt="Scanlan Theodore"
                loading="lazy"
              />
            </a>
          </div>
          <div>
            <a href="/search?brand=RALPH%20LAUREN">
              <img
                src="/static/popular-brands/Logo_RalphLauren.jpg"
                alt="Ralph Lauren"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export const LandingPageComponent = props => {
  const {
    pageAssetsData,
    listings,
    inProgress,
    error,
    user,
    latestArrivalListings,
    under50Listings,
  } = props;

  const pageData = pageAssetsData?.[camelize(ASSET_NAME)]?.data;

  const sectionUserName = {
    sectionId: 'test-section',
    sectionType: 'customUser',
  };

  const sectionPopularCategories = {
    sectionId: 'popular-categories',
    sectionType: 'popularCategories',
  };

  const latestProducts = {
    sectionId: 'latest-products',
    sectionType: 'latestProducts',
    listings: listings,
    user: user,
  };

  const sectionOrangeCard = {
    sectionId: 'orange-card',
    sectionType: 'orangeCard',
  };

  const sectionNewestArrivals = {
    sectionId: 'newest-arrivals',
    sectionType: 'newestArrivalsSection',
    listings: latestArrivalListings,
    under50Listings,
    user: user,
  };

  let customSections = pageData ? [sectionUserName, ...pageData.sections] : pageData?.sections;

  if (pageData?.sections) {
    customSections = insertItem(customSections, sectionPopularCategories, 1);
    customSections = insertItem(customSections, latestProducts, 2);
    customSections = insertItem(customSections, sectionNewestArrivals, 3);
    customSections = insertItem(customSections, sectionOrangeCard, 4);
  }

  return (
    <>
      <PageBuilder
        pageAssetsData={{
          ...pageData,
          sections: customSections,
        }}
        options={{
          sectionComponents: {
            customUser: { component: SectionUser },
            popularCategories: { component: popularCategories },
            latestProducts: { component: SectionLatestProducts },
            orangeCard: { component: SectionOrangeCard },
            newestArrivalsSection: { component: newestArrivalsSection },
          },
        }}
        inProgress={inProgress}
        error={error}
        fallbackPage={<FallbackPage error={error} />}
      />
    </>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  listingRefs: object,
  latestArrivals: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const {
    pageAssetsData,
    inProgress,
    listingRefs = [],
    error,
    latestArrivals = [],
    under50Listings = [],
  } = state.hostedAssets || {};
  const { currentUser: user } = state.user || {};

  const listings = listingRefs.length ? getMarketplaceEntities(state, listingRefs) : [];
  const latestArrivalListings = latestArrivals.length
    ? getMarketplaceEntities(state, latestArrivals)
    : [];
  const under50ListingsQ = under50Listings.length
    ? getMarketplaceEntities(state, under50Listings)
    : [];

  return {
    pageAssetsData,
    listings,
    latestArrivalListings,
    under50Listings: under50ListingsQ,
    inProgress,
    error,
    user,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
