import React from 'react';
import styles from './LoadingSkeleton.module.css';

const ListingCardSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        {[...Array(5)].map((_, index) => (
          <div key={index} className={styles.card}>
            {/* Main image skeleton with brand circle */}
            <div className={styles.imageWrapper}>
              <div className={styles.brandCircle}>
                <div className={styles.brandInner} />
              </div>
            </div>

            {/* Content section */}
            <div className={styles.contentWrapper}>
              {/* Price section */}
              <div className={styles.priceSection}>
                <div className={styles.priceWrapper}>
                  <div className={styles.mainPrice} />
                  <div className={styles.includedPrice} />
                </div>
              </div>

              {/* Title */}
              <div className={styles.title} />

              {/* Size */}
              <div className={styles.sizeWrapper}>
                <div className={styles.sizeLabel} />
                <div className={styles.sizeValue} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListingCardSkeleton;
